// ---------------------------------------------
// ---              FE constants             ---
// ---------------------------------------------


// impostazione stile SITO e abilitazione comboBox x cambiarlo
let SITE_STYLE_ACTIVE="C"
//const ENABLE_SWITCH_STILE = true;
const ENABLE_SWITCH_STILE = false;
const ENABLE_CREATE_ACCOUNT = false;
//const ENABLE_CREATE_ACCOUNT = true;

// identificazione cliente

const CUSTOMER = "MPM PROJECT";
const CUSTOMER_CODE = "0001";
const CUSTOMER_VIA = "Brembate (BG)";
const CUSTOMER_CITTA = "info@mpmproject.it";
const CUSTOMER_EMAIL = "www.mpmproject.it";


// stripeKey='pk_test_51OUw3IHYUETFznHdNruu2jhgzYf0wwJlSdP62oknyCMooXzDEJKRfXnNvLhJPbeFAScA69UdDvfVA5y0dQtJJ2sD00eh0tlXl0'
const STRIPE_PUBLIC_KEY_FE='pk_test_51OUw3IHYUETFznHdNruu2jhgzYf0wwJlSdP62oknyCMooXzDEJKRfXnNvLhJPbeFAScA69UdDvfVA5y0dQtJJ2sD00eh0tlXl0'

//const COLOR_TEXT_FOOTER_STYLE_A = "#F8F9FA"    // non fnz nel file css   grigione

const COLOR_TEXT_FOOTER_STYLE_A = "#616161"    // non fnz nel file css
const COLOR_BACKGROUND_MENU_STYLE_A = "#dadada"

const COLOR_TEXT_FOOTER_STYLE_B = "#F8F9FA"    // non fnz nel file css
const COLOR_BACKGROUND_MENU_STYLE_B = "#222f7f"


//const COLOR_TEXT_FOOTER_STYLE_C = "#707070"    // non fnz nel file css
//const COLOR_BACKGROUND_MENU1_STYLE_C = "#0D6EFD"
//const COLOR_BACKGROUND_MENU2_STYLE_C = "#F8F9FA"
const COLOR_TEXT_FOOTER_STYLE_C = "#222f7f"    // non fnz nel file css
const COLOR_BACKGROUND_MENU1_STYLE_C = "#222f7f"
const COLOR_BACKGROUND_MENU2_STYLE_C = "#e9e9e9"

const COLOR_TEXT_FOOTER_STYLE_D = "#222f7f"    // non fnz nel file css
const COLOR_BACKGROUND_MENU_STYLE_D = "#222f7f"

const DELAY_SLIDE_PAGE = 1500;

/*
const CUSTOMER_SLIDE_URL_ARRAY = [
  "https://www.shoppingclick.it/myplace/storage-images/customer-0001/categorie/categoria-abbigliamentodonna.jpg",
  "https://www.shoppingclick.it/myplace/storage-images/customer-0001/categorie/categoria-accessoridonna.jpg",
  "https://www.shoppingclick.it/myplace/storage-images/customer-0001/categorie/categoria-attrezzigiardino.jpg",
  "https://www.shoppingclick.it/myplace/storage-images/customer-0001/categorie/categoria-computers.jpg",
  "https://www.shoppingclick.it/myplace/storage-images/customer-0001/categorie/categoria-games.jpg",
  "https://www.shoppingclick.it/myplace/storage-images/customer-0001/categorie/categoria-lavatrici.jpg",
  "https://www.shoppingclick.it/myplace/storage-images/customer-0001/categorie/categoria-trapani.jpg",
  "https://www.shoppingclick.it/myplace/storage-images/customer-0001/categorie/categoria-tablets.jpg",
];
*/

const CUSTOMER_SLIDE_URL_ARRAY = [
  "/images-0001/pages/planner/planner-slide1.jpg",
  "/images-0001/pages/planner/planner-slide2.jpg",
  "/images-0001/pages/planner/planner-slide3.jpg",
  "/images-0001/pages/planner/planner-slide4.jpg",
  "/images-0001/pages/shop/shop-slide1.jpg",
  "/images-0001/pages/shop/shop-slide2.jpg",
  "/images-0001/pages/shop/shop-slide3.jpg",
  "/images-0001/pages/shop/shop-slide4.jpg",
  "/images-0001/pages/studio/studio-slide1.jpg",
  "/images-0001/pages/studio/studio-slide4.jpg",
  "/images-0001/pages/studio/studio-slide3.jpg",
  "/images-0001/pages/studio/studio-slide5.jpg",
];

const PAGE_PLANNER_TITOLO = "Gestione agenda, risorse, prenotazioni Stripe™ con carta di credito";
const PAGE_PLANNER_PRODOTTO1_DESCR = "MyPlanner sitoWeb";
const PAGE_PLANNER_PRODOTTO1_DESCR1 = " ";
const PAGE_PLANNER_PRODOTTO1_PREZZO = "€ 446,00";
const PAGE_PLANNER_PRODOTTO2_DESCR = "MyPlanner sitoWeb";
const PAGE_PLANNER_PRODOTTO2_DESCR1 = "+ 3 email";
const PAGE_PLANNER_PRODOTTO2_PREZZO = "€ 609,00";
const PAGE_PLANNER_PRODOTTO3_DESCR = "MyPlanner full";
const PAGE_PLANNER_PRODOTTO3_DESCR1 = "";
const PAGE_PLANNER_PRODOTTO3_PREZZO = "€ 476,00 ";
const PAGE_PLANNER_PRODOTTO4_DESCR = "MyPlanner full";
const PAGE_PLANNER_PRODOTTO4_DESCR1 = "+ 3 email";
const PAGE_PLANNER_PRODOTTO4_PREZZO = "€ 616,00 ";
const PAGE_PLANNER_TESTO = "Siti per Negozi, centri estetici e parrucchieri, professionisti. Studiato per la prenotazione degli appuntamenti. Il cliente fidelizzato può prenotare da casa e pagare l'acconto con carta di credito. \
Pianificazione appuntamenti in base al personale disponibile, stanze e servizi. \
Scegli il tuo servizio tra quelli disponibili nella categoria. Ogni contenuto e dinamico e le foto le carichi dal Mobile";

const PAGE_COUPON_TITOLO = "seguiamo Clienti ed Iniziative di rilievo nazionale"
const PAGE_COUPON_TESTO = "Il nostro Studio ritiene che la professione debba essere sempre e costantemente al passo con le nuove tecnologie.  \
Crediamo nella costante crescita delle Professionalità. Riteniamo fondamentale il continuo aggiornamento dei nostri Professionisti. \
Le conoscenze offerte dal nostro team non sono seconde a nessuno. Il nostro team di ricerca e sviluppo lavora costantemente per rilevare le novità e le migliori soluzioni.";
const PAGE_COUPON_SLIDE_1_URL = "https://www.shoppingclick.it/myplanner/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide4.jpg";
const PAGE_COUPON_SLIDE_1_TITOLO = "vantiamo Clienti di spicco";
const PAGE_COUPON_SLIDE_1_SOTTOTITOLO = "per garantirti la massima professionalità";
const PAGE_COUPON_SLIDE_2_URL = "https://www.shoppingclick.it/myplanner/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide5.jpg";
const PAGE_COUPON_SLIDE_2_TITOLO = "vantiamo Clienti di spicco";
const PAGE_COUPON_SLIDE_2_SOTTOTITOLO = "per garantirti la massima professionalità";
const PAGE_COUPON_SLIDE_3_URL = "https://www.shoppingclick.it/myplanner/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide6.jpg";
const PAGE_COUPON_SLIDE_3_TITOLO = "vantiamo Clienti di spicco";
const PAGE_COUPON_SLIDE_3_SOTTOTITOLO = "per garantirti la massima professionalità";
                
const PAGE_GIFTCARD_TITOLO = "Seguici"
const PAGE_GIFTCARD_TESTO = "Seguiamo costantemente Convegni del settore perchè conosciamo l'importanza dei continui aggiornamenti professionali. \
Crediamo nella costante crescita delle Professionalità. Riteniamo fondamentale il continuo aggiornamento dei nostri Professionisti. \
Abbiamo a cuore le tue problematiche e garantiamo la migliore soluzione possibile.";
const PAGE_GIFTCARD_SLIDE_1_URL = "https://www.shoppingclick.it/myplanner/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide61.jpg";
const PAGE_GIFTCARD_SLIDE_1_TITOLO = "Pubblicazioni per lasciare il segno della nostra professionalità";
const PAGE_GIFTCARD_SLIDE_1_SOTTOTITOLO = "Eveti per condividere informazioni e metodologie";
const PAGE_GIFTCARD_SLIDE_2_URL = "https://www.shoppingclick.it/myplanner/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide62.jpg";
const PAGE_GIFTCARD_SLIDE_2_TITOLO = "Pubblicazioni per lasciare il segno della nostra professionalità";
const PAGE_GIFTCARD_SLIDE_2_SOTTOTITOLO = "Eveti per condividere informazioni e metodologie";
const PAGE_GIFTCARD_SLIDE_3_URL = "https://www.shoppingclick.it/myplanner/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide63.jpg";
const PAGE_GIFTCARD_SLIDE_3_TITOLO = "Pubblicazioni per lasciare il segno della nostra professionalità";
const PAGE_GIFTCARD_SLIDE_3_SOTTOTITOLO = "Eveti per condividere informazioni e metodologie";
                
const PAGE_NEWS_TITOLO = "Seguici"
const PAGE_NEWS_TESTO = "Instauriamo con i nostri clienti una profiqua comunicazione su tutto ciò che può interessare la miglior soluzione praticabile. \
Crediamo nel continuo esame delle problematiche al fine di rettificare quanto inerente le esigenze dei Clienti. Riteniamo fondamentale il continuo aggiornamento dei nostri Professionisti. \
Abbiamo a cuore le tue problematiche e garantiamo la migliore soluzione possibile.";
const PAGE_NEWS_SLIDE_1_URL = "https://www.shoppingclick.it/myplanner/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide64.jpg";
const PAGE_NEWS_SLIDE_1_TITOLO = "condividiamo conoscenze e modi di pensare";
const PAGE_NEWS_SLIDE_1_SOTTOTITOLO = "pubblichiamo approfondimenti";
const PAGE_NEWS_SLIDE_2_URL = "https://www.shoppingclick.it/myplanner/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide65.jpg";
const PAGE_NEWS_SLIDE_2_TITOLO = "condividiamo conoscenze e modi di pensare";
const PAGE_NEWS_SLIDE_2_SOTTOTITOLO = "pubblichiamo approfondimenti";
const PAGE_NEWS_SLIDE_3_URL = "https://www.shoppingclick.it/myplanner/storage-images/customer-"+CUSTOMER_CODE+"/slides/slide66.jpg";
const PAGE_NEWS_SLIDE_3_TITOLO = "condividiamo conoscenze e modi di pensare";
const PAGE_NEWS_SLIDE_3_SOTTOTITOLO = "pubblichiamo approfondimenti";

// prelievo IMMAGINI CLIENTE da www.shoppingclick.it (non utilizzato)
// =>/home/shouser10/htdocs/www.shoppingclick.it/myplanner/storage-images/site-myplanneronline-it
const URL_IMAGES_CUSTOMER = "https://www.shoppingclick.it/mpmproject-it/storage-images/customer-"+CUSTOMER_CODE+"/";

module.exports = { 
  ENABLE_SWITCH_STILE,
  ENABLE_CREATE_ACCOUNT,
  SITE_STYLE_ACTIVE,
  CUSTOMER,
  CUSTOMER_CODE,
  CUSTOMER_VIA,
  CUSTOMER_CITTA,
  CUSTOMER_EMAIL,
  STRIPE_PUBLIC_KEY_FE,

  COLOR_BACKGROUND_MENU_STYLE_A,
  COLOR_TEXT_FOOTER_STYLE_A,
  COLOR_TEXT_FOOTER_STYLE_B,
  COLOR_BACKGROUND_MENU_STYLE_B,
  COLOR_TEXT_FOOTER_STYLE_C,
  COLOR_BACKGROUND_MENU1_STYLE_C,
  COLOR_BACKGROUND_MENU2_STYLE_C,
  COLOR_BACKGROUND_MENU_STYLE_D,
  COLOR_TEXT_FOOTER_STYLE_D,
  CUSTOMER_SLIDE_URL_ARRAY,

  DELAY_SLIDE_PAGE,
  
  PAGE_PLANNER_TITOLO,
  PAGE_PLANNER_TESTO,
  PAGE_PLANNER_PRODOTTO1_DESCR,
  PAGE_PLANNER_PRODOTTO1_DESCR1,
  PAGE_PLANNER_PRODOTTO1_PREZZO,
  PAGE_PLANNER_PRODOTTO2_DESCR,
  PAGE_PLANNER_PRODOTTO2_DESCR1,
  PAGE_PLANNER_PRODOTTO2_PREZZO,
  PAGE_PLANNER_PRODOTTO3_DESCR,
  PAGE_PLANNER_PRODOTTO3_DESCR1,
  PAGE_PLANNER_PRODOTTO3_PREZZO,
  PAGE_PLANNER_PRODOTTO4_DESCR,
  PAGE_PLANNER_PRODOTTO4_DESCR1,
  PAGE_PLANNER_PRODOTTO4_PREZZO,

  PAGE_COUPON_TITOLO,
  PAGE_COUPON_TESTO,
  PAGE_COUPON_SLIDE_1_URL,
  PAGE_COUPON_SLIDE_1_TITOLO,
  PAGE_COUPON_SLIDE_1_SOTTOTITOLO,
  PAGE_COUPON_SLIDE_2_URL,
  PAGE_COUPON_SLIDE_2_TITOLO,
  PAGE_COUPON_SLIDE_2_SOTTOTITOLO,
  PAGE_COUPON_SLIDE_3_URL,
  PAGE_COUPON_SLIDE_3_TITOLO,
  PAGE_COUPON_SLIDE_3_SOTTOTITOLO,

  PAGE_GIFTCARD_TITOLO,
  PAGE_GIFTCARD_TESTO,
  PAGE_GIFTCARD_SLIDE_1_URL,
  PAGE_GIFTCARD_SLIDE_1_TITOLO,
  PAGE_GIFTCARD_SLIDE_1_SOTTOTITOLO,
  PAGE_GIFTCARD_SLIDE_2_URL,
  PAGE_GIFTCARD_SLIDE_2_TITOLO,
  PAGE_GIFTCARD_SLIDE_2_SOTTOTITOLO,
  PAGE_GIFTCARD_SLIDE_3_URL,
  PAGE_GIFTCARD_SLIDE_3_TITOLO,
  PAGE_GIFTCARD_SLIDE_3_SOTTOTITOLO,
   
  PAGE_NEWS_TITOLO,
  PAGE_NEWS_TESTO,
  PAGE_NEWS_SLIDE_1_URL,
  PAGE_NEWS_SLIDE_1_TITOLO,
  PAGE_NEWS_SLIDE_1_SOTTOTITOLO,
  PAGE_NEWS_SLIDE_2_URL,
  PAGE_NEWS_SLIDE_2_TITOLO,
  PAGE_NEWS_SLIDE_2_SOTTOTITOLO,
  PAGE_NEWS_SLIDE_3_URL,
  PAGE_NEWS_SLIDE_3_TITOLO,
  PAGE_NEWS_SLIDE_3_SOTTOTITOLO,
  
  URL_IMAGES_CUSTOMER,
};
