import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import 'bootstrap/dist/css/bootstrap.css'; 
import Carousel from 'react-bootstrap/Carousel'; 
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import Typography from '@mui/material/Typography';
import DownloadTwoToneIcon from '@mui/icons-material/DownloadTwoTone';

import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import SourceTwoToneIcon from '@mui/icons-material/SourceTwoTone';
import InsertInvitationTwoToneIcon from '@mui/icons-material/InsertInvitationTwoTone';
import StyleTwoToneIcon from '@mui/icons-material/StyleTwoTone';
import AutoFixHighTwoToneIcon from '@mui/icons-material/AutoFixHighTwoTone';
import axios from "axios";

const { strCurrency, strEuroCurrency } = require('../../utils/currencyUtils.js')
const config = require("../../constants/constants.js")


const IdeaPageComponent = ({ loginUserApiRequest,reduxDispatch, setReduxUserState  }) => {
  const [validated, setValidated] = useState(false);
  const [loginUserResponseState, setLoginUserResponseState] = useState({
    success: "",
    error: "",
    loading: false,
  });

  const navigate = useNavigate();

  const config = require("../../constants/constants.js")
  const site_image_brands = "../images-"+config.CUSTOMER_CODE+config.URL_IMAGES_CUST_COUPONS;

  const [page, setPage] = useState({});  
  const [id, setId] = useState("");                      
  const [title, setTitle] = useState("");                      
  const [subTitle, setSubTitle] = useState("");     
  const [description, setDescription] = useState("");     
  const [price, setPrice] = useState(0);
  const [customizedName, setCustomizedName] = useState("");     
  const [customizedStreet, setCustomizedStreet] = useState("");     
  const [customizedZip, setCustomizedZip] = useState("");     
  const [customizedCity, setCustomizedCity] = useState("");     
  const [customizedEmail, setCustomizedEmail] = useState("");     
  const [tabAttivo, setTabAttivo] = React.useState('home');

  const [pathImage1, setPathImage1] = useState("");     
  const [pathImage2, setPathImage2] = useState("");     
  const [pathImage3, setPathImage3] = useState("");     
  const [pathImage4, setPathImage4] = useState("");     
  const [pathImage5, setPathImage5] = useState("");     

  const [pathImage11, setPathImage11] = useState("");     
  const [pathImage21, setPathImage21] = useState("");     
  const [pathImage31, setPathImage31] = useState("");     
  const [pathImage41, setPathImage41] = useState("");     
  const [pathImage42, setPathImage42] = useState("");     
  const [pathImage43, setPathImage43] = useState("");     
  const [pathImage44, setPathImage44] = useState("");     
  const [pathImage45, setPathImage45] = useState("");     
  const [pathImage51, setPathImage51] = useState("");     
  const [pathImage61, setPathImage61] = useState("");     
  const [pathImage62, setPathImage62] = useState("");     
  const [pathImage63, setPathImage63] = useState("");     
  const [pathImage64, setPathImage64] = useState("");     
    
  const [titleImage1, setTitleImage1] = useState("");     
  const [subTitleImage1, setSubTitleImage1] = useState("");     
  const [titleImage2, setTitleImage2] = useState("");     
  const [subTitleImage2, setSubTitleImage2] = useState("");     
  const [titleImage3, setTitleImage3] = useState("");     
  const [subTitleImage3, setSubTitleImage3] = useState("");      
  const [titleImage4, setTitleImage4] = useState("");     
  const [subTitleImage4, setSubTitleImage4] = useState("");     
  const [titleImage5, setTitleImage5] = useState("");     
  const [subTitleImage5, setSubTitleImage5] = useState("");      

  const [openPoint11, setOpenPoint11] = React.useState(false);
  const [openPoint21, setOpenPoint21] = React.useState(false);
  const [openPoint31, setOpenPoint31] = React.useState(false);
  const [openPoint41, setOpenPoint41] = React.useState(false);
  const [openPoint42, setOpenPoint42] = React.useState(false);
  const [openPoint43, setOpenPoint43] = React.useState(false);
  const [openPoint44, setOpenPoint44] = React.useState(false);
  const [openPoint45, setOpenPoint45] = React.useState(false);
  const [openPoint51, setOpenPoint51] = React.useState(false);
  const [openPoint60, setOpenPoint60] = React.useState(false);
  const [openPoint61, setOpenPoint61] = React.useState(false);
  const [openPoint62, setOpenPoint62] = React.useState(false);
  const [openPoint63, setOpenPoint63] = React.useState(false);
  const [openPoint64, setOpenPoint64] = React.useState(false);

  const handleClickPoint11 = () => {
    setOpenPoint11(!openPoint11);
  };

  const handleClickPoint21 = () => {
    setOpenPoint21(!openPoint21);
  };

  const handleClickPoint31 = () => {
    setOpenPoint31(!openPoint31);
  };

  const handleClickPoint41 = () => {
    setOpenPoint41(!openPoint41);
  };

  const handleClickPoint42 = () => {
    setOpenPoint42(!openPoint42);
  };

  const handleClickPoint43 = () => {
    setOpenPoint43(!openPoint43);
  };

  const handleClickPoint44 = () => {
    setOpenPoint44(!openPoint44);
  };

  const handleClickPoint45 = () => {
    setOpenPoint45(!openPoint45);
  };

  const handleClickPoint51 = () => {
    setOpenPoint51(!openPoint51);
  };

  const handleClickPoint60 = () => {
    setOpenPoint60(!openPoint60);
  };

  const handleClickPoint61 = () => {
    setOpenPoint61(!openPoint61);
  };

  const handleClickPoint62 = () => {
    setOpenPoint62(!openPoint62);
  };

  const handleClickPoint63 = () => {
    setOpenPoint63(!openPoint63);
  };

  const handleClickPoint64 = () => {
    setOpenPoint64(!openPoint64);
  };

  const getPageByMenuValueApiRequest = async (menuValue) => {
    console.log("[getPageByMenuValueApiRequest] leggo page con menuValue =",menuValue);
    const url = `/api/pages/menuValue/`+menuValue;
    const { data } = await axios.get(url);
    console.log(`data =`, data);  
    return data
  }

  useEffect(() => {
    getPageByMenuValueApiRequest('IDEA').then((data) => { 
      console.log(`data 1 =`, data);  
      setPage(data);
      setTitle(data?.title.text);
      setSubTitle(data?.subTitle.text);
      setDescription(data?.description.text);
      setPrice(data?.price);
      setId(data?._id);
      setPathImage1(data?.images[0].path);
      setPathImage2(data?.images[1].path);
      setPathImage3(data?.images[2].path);
      setPathImage4(data?.images[3].path);
      setPathImage5(data?.images[4].path);

      setPathImage11(data?.images[5].path);
      setPathImage31(data?.images[6].path);
      setPathImage61(data?.images[7].path);
      setPathImage62(data?.images[8].path);
      setPathImage63(data?.images[9].path);
      setPathImage64(data?.images[10].path);
      
      setCustomizedName(page?.customizedContent.name.text);
      setCustomizedStreet(page?.customizedContent.street.text);
      setCustomizedZip(page?.customizedContent.zip.text);
      setCustomizedCity(page?.customizedContent.city.text);
      setCustomizedEmail(page?.customizedContent.email.text);
      setTitleImage1(data?.imagesTitle[0].text);
      setSubTitleImage1(data?.imagesSubTitle[0].text);
      setTitleImage2(data?.imagesTitle[1].text);
      setSubTitleImage2(data?.imagesSubTitle[1].text);
      setTitleImage3(data?.imagesTitle[2].text);
      setSubTitleImage3(data?.imagesSubTitle[2].text);
      setTitleImage4(data?.imagesTitle[3].text);
      setSubTitleImage4(data?.imagesSubTitle[3].text);
      setTitleImage5(data?.imagesTitle[4].text);
      setSubTitleImage5(data?.imagesSubTitle[4].text);
      console.log(`page?.title.text =`, page?.title.text);  
      console.log(`page?.description.text =`, page?.description.text);  
      console.log(`page?.customizedContent =`, page?.customizedContent);  
      console.log(`page?.customizedContent.name.text =`, page?.customizedContent.zip.text);  
    })
    .catch((er) => {
      console.log(er);
    });  
  }, [title,id])

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (

    <Container className="px-0">
    
    <Row
      g-0
      className="pt-2 pt-md-5 w-100 px-4 px-xl-0 position-relative"
    >
    
      <Col
        md={{ size: 4 }}
        tag="aside"
        className="pb-5 mb-5 pb-md-0 mb-md-0 mx-auto mx-md-0"
      >

        <Carousel> 
          <Carousel.Item interval={config.DELAY_SLIDE_PAGE}> 
            <img 
              className="d-block w-100"
              src={pathImage1}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{titleImage1}</h3> 
              <p>{subTitleImage1}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
          <Carousel.Item interval={config.DELAY_SLIDE_PAGE}> 
            <img 
              className="d-block w-100"
              src={pathImage2}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{titleImage2}</h3> 
              <p>{subTitleImage2}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
          <Carousel.Item interval={config.DELAY_SLIDE_PAGE}> 
            <img 
              className="d-block w-100"
              src={pathImage3}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{titleImage3}</h3> 
              <p>{subTitleImage3}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
          <Carousel.Item interval={config.DELAY_SLIDE_PAGE}> 
            <img 
              className="d-block w-100"
              src={pathImage4}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{titleImage4}</h3> 
              <p>{subTitleImage4}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
          <Carousel.Item interval={config.DELAY_SLIDE_PAGE}> 
            <img 
              className="d-block w-100"
              src={pathImage5}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{titleImage5}</h3> 
              <p>{subTitleImage5}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
        </Carousel> 

      </Col>

      <Col
        md={{ size: 5, offset: 0 }}
        tag="section"
        className="py-5 mb-5 py-md-0 mb-md-0"
      >

        <h2
          style={{
            color: "#1136a0",
          }}
          >
          {title}
        </h2>

        <Form.Group className="mb-3" controlId="validationCustom01" >
          <Form.Label><em><b>{subTitle}</b></em></Form.Label>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>
            {description} 
          </Form.Label>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Button variant="primary" size="small" onClick={() => openInNewTab('https://myideaonline.it')} >
            accedi a myIdea
          </Button>
          <Typography sx={{ color: '#1136a0', fontSize: 12, paddingBottom: 1 }}>
            &nbsp;&nbsp;clicca per accedere al portale esempio
          </Typography>
        </Form.Group>

      </Col>
    
      <Col
        md={{ size: 3, offset: 0 }}
      >

        <Row className="m-0">
         
            <Col md={12}>     


            <h1
              style={{
                color: "#298458",
              }}
              >
              MyIdea
            </h1>

              <Box
                sx={{ '& > :not(style)': { m: 1 } }}
                noValidate
                autoComplete="off"
              >
                <TextField id="id-name" label="Prezzo" variant="outlined" value={strEuroCurrency(price)} 
                                style={{
                                  width: "13ch",
                                }}
                
                  size="small"
                  slotProps={{
                    input: { readOnly: true },
                    inputLabel: { shrink: true },
                  }}             
                  onChange={(event) => {
                  //setName(event.target.value);
                  }}
                />

              <Button variant='primary' size="small">
                <a href="/files-dwn/idea-v1.pdf" target="_blank" download                 
                  style={{
                    color: "#FFFFFF",
                    textDecoration: "none"
                  }
                }>
                  PDF<DownloadTwoToneIcon></DownloadTwoToneIcon>
                </a>
              </Button>

             </Box>
             <Typography sx={{ color: '#1136a0', fontSize: 12 }}>
                  &nbsp;&nbsp;&nbsp;URL a tua scelta, assistenza. Tutto in 3 giorni
              </Typography>

             <Col md={8}>     
             </Col>
            <Col md={6}>     
            </Col>

             <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' , color: '#294784' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <ListItemButton onClick={handleClickPoint11}>
                  <ListItemIcon>
                    <CalendarMonthTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Catalogo prodotti/servizi" />
                  {openPoint11 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openPoint11} timeout="auto" unmountOnExit>
                  <Box
                      component="img"
                      sx={{
                        height: 233,
                        width: 350,
                        maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 250 },
                      }}
                      alt="The house from the offer."
                      src={pathImage11}
                  />
                  <Typography sx={{ color: '#1136a0', fontSize: 12 }}>
                    &nbsp;&nbsp;&nbsp;una pagina per i dettagli di ogni prodotto 
                  </Typography>
                </Collapse>

                <ListItemButton onClick={handleClickPoint21}>
                  <ListItemIcon>
                    <SourceTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Contenuti dinamici"/>
                  {openPoint21 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openPoint21} timeout="auto" unmountOnExit>
                  <Typography sx={{ color: '#1136a0', fontSize: 12 , border: 1 , paddingTop: 1}}>
                    <ul>
                    <li>con accesso Admin puoi cambiare foto e scritte</li>
                    <li>gestisci e memorizza i clienti</li>
                    <li>carica le foto dal tuo telefono, verranno ottimizzate in automatico</li>
                    <li>cambia le immagini e i testi per occasioni/eventi</li>
                    </ul>
                  </Typography>
                </Collapse>


                <ListItemButton onClick={handleClickPoint41}>
                  <ListItemIcon>
                    <StyleTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Chat con i tuoi clienti" />
                  {openPoint41 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openPoint41} timeout="auto" unmountOnExit>
                  <Box
                      component="img"
                      sx={{
                        height: 233,
                        width: 350,
                        maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 250 },
                      }}
                      alt="The house from the offer."
                      src={pathImage31}
                  />
                  <Typography sx={{ color: '#1136a0', fontSize: 12 }}>
                    &nbsp;abilita i Clienti che vuoi alla chat esclusiva con te 
                  </Typography>
                </Collapse>

                <ListItemButton onClick={handleClickPoint60}>
                  <ListItemIcon>
                    <AutoFixHighTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Scegli il tuo WebStyle" />
                  {openPoint60 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openPoint60} timeout="auto" unmountOnExit>
                <Typography sx={{ color: '#1136a0', fontSize: 12 , border: 1 , padding: 1}}>
                    <li>con MyIdea puoi personalizzare il tuo portale scegliendo lo stile che preferisci</li>
                    <li>WebStyle disponibili:</li>
                    <ol>
                      <li>Classic</li>
                      <li>Jazz</li>
                      <li>Modern</li>
                      <li>Time</li>
                    </ol>
                    <li>Ogni anno rilasciamo altri Stili che potrai adottare</li>
                  </Typography>

            {/* 
                  <List component="div" disablePadding>
                    <ListItemButton onClick={handleClickPoint61} sx={{ pl: 4 , color: '#298458' }}>
                      <ListItemIcon>
                        <ArticleTwoToneIcon />
                      </ListItemIcon>
                      <ListItemText primary="Classic" />
                    </ListItemButton>
                    <Collapse in={openPoint61} timeout="auto" unmountOnExit>
                      <Box
                          component="img"
                          sx={{
                            height: 233,
                            width: 350,
                            maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 350, md: 250 },
                          }}
                          alt="The house from the offer."
                          src={pathImage61}
                      />
                      <Typography sx={{ color: '#1136a0', fontSize: 12 }}>
                        &nbsp;personalizza foto e scritte, cambiale quando vuoi 
                      </Typography>
                    </Collapse>

                    <ListItemButton onClick={handleClickPoint62} sx={{ pl: 4, color: '#298458' }}>
                      <ListItemIcon>
                        <ArticleTwoToneIcon />
                      </ListItemIcon>
                      <ListItemText primary="Jazz" />
                    </ListItemButton>
                    <Collapse in={openPoint62} timeout="auto" unmountOnExit>
                      <Box
                          component="img"
                          sx={{
                            height: 233,
                            width: 350,
                            maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 350, md: 250 },
                          }}
                          alt="The house from the offer."
                          src={pathImage62}
                      />
                      <Typography sx={{ color: '#1136a0', fontSize: 12 }}>
                        &nbsp;personalizza foto e scritte, cambiale quando vuoi 
                      </Typography>
                    </Collapse>

                    <ListItemButton onClick={handleClickPoint63} sx={{ pl: 4 , color: '#298458' }}>
                      <ListItemIcon>
                        <ArticleTwoToneIcon />
                      </ListItemIcon>
                      <ListItemText primary="Modern" />
                    </ListItemButton>
                    <Collapse in={openPoint63} timeout="auto" unmountOnExit>
                      <Box
                          component="img"
                          sx={{
                            height: 233,
                            width: 350,
                            maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 350, md: 250 },
                          }}
                          alt="The house from the offer."
                          src={pathImage63}
                      />
                      <Typography sx={{ color: '#1136a0', fontSize: 12 }}>
                        &nbsp;personalizza foto e scritte, cambiale quando vuoi 
                      </Typography>
                    </Collapse>

                    <ListItemButton onClick={handleClickPoint64} sx={{ pl: 4 , color: '#298458' }}>
                      <ListItemIcon>
                        <ArticleTwoToneIcon />
                      </ListItemIcon>
                      <ListItemText primary="Time" />
                    </ListItemButton>
                    <Collapse in={openPoint64} timeout="auto" unmountOnExit>
                      <Box
                          component="img"
                          sx={{
                            height: 233,
                            width: 350,
                            maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 350, md: 250 },
                          }}
                          alt="The house from the offer."
                          src={pathImage64}
                      />
                      <Typography sx={{ color: '#1136a0', fontSize: 12 }}>
                        &nbsp;personalizza foto e scritte, cambiale quando vuoi  
                      </Typography>

                    </Collapse>

                  </List>
            */}

                </Collapse>


              </List>
    
    

            </Col>
          </Row>

    </Col>

           

  </Row>

    <Row className="mt-5 justify-content-md-center">
      <Col md={6}>
      </Col>
    </Row>

  </Container>

  );
};

export default IdeaPageComponent;
