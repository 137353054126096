import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import 'bootstrap/dist/css/bootstrap.css'; 
import Carousel from 'react-bootstrap/Carousel'; 
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import Typography from '@mui/material/Typography';
import DownloadTwoToneIcon from '@mui/icons-material/DownloadTwoTone';

import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import SourceTwoToneIcon from '@mui/icons-material/SourceTwoTone';
import InsertInvitationTwoToneIcon from '@mui/icons-material/InsertInvitationTwoTone';
import StyleTwoToneIcon from '@mui/icons-material/StyleTwoTone';
import AutoFixHighTwoToneIcon from '@mui/icons-material/AutoFixHighTwoTone';
import axios from "axios";

const { strCurrency, strEuroCurrency } = require('../../utils/currencyUtils.js')
const config = require("../../constants/constants.js")


const ShopPageComponent = ({ loginUserApiRequest,reduxDispatch, setReduxUserState  }) => {
  const [validated, setValidated] = useState(false);
  const [loginUserResponseState, setLoginUserResponseState] = useState({
    success: "",
    error: "",
    loading: false,
  });

  const navigate = useNavigate();

  const config = require("../../constants/constants.js")
  const site_image_brands = "../images-"+config.CUSTOMER_CODE+config.URL_IMAGES_CUST_COUPONS;

  const [page, setPage] = useState({});  
  const [id, setId] = useState("");                      
  const [title, setTitle] = useState("");                      
  const [subTitle, setSubTitle] = useState("");     
  const [description, setDescription] = useState("");     
  const [price, setPrice] = useState(0);
  const [customizedName, setCustomizedName] = useState("");     
  const [customizedStreet, setCustomizedStreet] = useState("");     
  const [customizedZip, setCustomizedZip] = useState("");     
  const [customizedCity, setCustomizedCity] = useState("");     
  const [customizedEmail, setCustomizedEmail] = useState("");     
  const [tabAttivo, setTabAttivo] = React.useState('home');

  const [pathImage1, setPathImage1] = useState("");     
  const [pathImage2, setPathImage2] = useState("");     
  const [pathImage3, setPathImage3] = useState("");     
  const [pathImage4, setPathImage4] = useState("");     
  const [pathImage5, setPathImage5] = useState("");     

  const [pathImage11, setPathImage11] = useState("");     
  const [pathImage12, setPathImage12] = useState("");     
  const [pathImage13, setPathImage13] = useState("");     
  const [pathImage14, setPathImage14] = useState("");     
  const [pathImage15, setPathImage15] = useState("");     
  const [pathImage21, setPathImage21] = useState("");     
  const [pathImage31, setPathImage31] = useState("");     
  const [pathImage41, setPathImage41] = useState("");     
  const [pathImage42, setPathImage42] = useState("");     
  const [pathImage43, setPathImage43] = useState("");     
  const [pathImage44, setPathImage44] = useState("");     
  const [pathImage45, setPathImage45] = useState("");     
  const [pathImage51, setPathImage51] = useState("");     
  const [pathImage61, setPathImage61] = useState("");     
  const [pathImage62, setPathImage62] = useState("");     
  const [pathImage63, setPathImage63] = useState("");     
  const [pathImage64, setPathImage64] = useState("");     
  const [pathImage65, setPathImage65] = useState("");     
  const [pathImage71, setPathImage71] = useState("");     
  const [pathImage72, setPathImage72] = useState("");     
  const [pathImage73, setPathImage73] = useState("");     
  const [pathImage74, setPathImage74] = useState("");     
  const [pathImage75, setPathImage75] = useState("");     
    
  const [titleImage1, setTitleImage1] = useState("");     
  const [subTitleImage1, setSubTitleImage1] = useState("");     
  const [titleImage2, setTitleImage2] = useState("");     
  const [subTitleImage2, setSubTitleImage2] = useState("");     
  const [titleImage3, setTitleImage3] = useState("");     
  const [subTitleImage3, setSubTitleImage3] = useState("");      
  const [titleImage4, setTitleImage4] = useState("");     
  const [subTitleImage4, setSubTitleImage4] = useState("");     
  const [titleImage5, setTitleImage5] = useState("");     
  const [subTitleImage5, setSubTitleImage5] = useState("");      

  const [openPoint11, setOpenPoint11] = React.useState(false);
  const [openPoint12, setOpenPoint12] = React.useState(false);
  const [openPoint13, setOpenPoint13] = React.useState(false);
  const [openPoint14, setOpenPoint14] = React.useState(false);
  const [openPoint15, setOpenPoint15] = React.useState(false); 
  const [openPoint21, setOpenPoint21] = React.useState(false);
  const [openPoint31, setOpenPoint31] = React.useState(false);
  const [openPoint41, setOpenPoint41] = React.useState(false);
  const [openPoint42, setOpenPoint42] = React.useState(false);
  const [openPoint43, setOpenPoint43] = React.useState(false);
  const [openPoint44, setOpenPoint44] = React.useState(false);
  const [openPoint45, setOpenPoint45] = React.useState(false);
  const [openPoint51, setOpenPoint51] = React.useState(false);
  const [openPoint52, setOpenPoint52] = React.useState(false);
  const [openPoint60, setOpenPoint60] = React.useState(false);
  const [openPoint61, setOpenPoint61] = React.useState(false);
  const [openPoint62, setOpenPoint62] = React.useState(false);
  const [openPoint63, setOpenPoint63] = React.useState(false);
  const [openPoint64, setOpenPoint64] = React.useState(false);
  const [openPoint65, setOpenPoint65] = React.useState(false);
  const [openPoint70, setOpenPoint70] = React.useState(false);
  const [openPoint71, setOpenPoint71] = React.useState(false);
  const [openPoint72, setOpenPoint72] = React.useState(false);
  const [openPoint73, setOpenPoint73] = React.useState(false);
  const [openPoint74, setOpenPoint74] = React.useState(false);
  const [openPoint75, setOpenPoint75] = React.useState(false);

  const handleClickPoint11 = () => {
    setOpenPoint11(!openPoint11);
  };

  const handleClickPoint12 = () => {
    setOpenPoint12(!openPoint12);
  };
  
  const handleClickPoint13 = () => {
    setOpenPoint13(!openPoint13);
  };
  
  const handleClickPoint14 = () => {
    setOpenPoint14(!openPoint14);
  };
  
  const handleClickPoint15 = () => {
    setOpenPoint15(!openPoint15);
  };

  const handleClickPoint21 = () => {
    setOpenPoint21(!openPoint21);
  };

  const handleClickPoint31 = () => {
    setOpenPoint31(!openPoint31);
  };

  const handleClickPoint41 = () => {
    setOpenPoint41(!openPoint41);
  };

  const handleClickPoint42 = () => {
    setOpenPoint42(!openPoint42);
  };

  const handleClickPoint43 = () => {
    setOpenPoint43(!openPoint43);
  };

  const handleClickPoint44 = () => {
    setOpenPoint44(!openPoint44);
  };

  const handleClickPoint45 = () => {
    setOpenPoint45(!openPoint45);
  };

  const handleClickPoint51 = () => {
    setOpenPoint51(!openPoint51);
  };

  const handleClickPoint52 = () => {
    setOpenPoint52(!openPoint52);
  };

  const handleClickPoint60 = () => {
    setOpenPoint60(!openPoint60);
  };

  const handleClickPoint61 = () => {
    setOpenPoint61(!openPoint61);
  };

  const handleClickPoint62 = () => {
    setOpenPoint62(!openPoint62);
  };

  const handleClickPoint63 = () => {
    setOpenPoint63(!openPoint63);
  };

  const handleClickPoint64 = () => {
    setOpenPoint64(!openPoint64);
  };

  const handleClickPoint65 = () => {
    setOpenPoint65(!openPoint65);
  };

  const handleClickPoint70 = () => {
    setOpenPoint70(!openPoint70);
  };

  const handleClickPoint71 = () => {
    setOpenPoint71(!openPoint71);
  };

  const handleClickPoint72 = () => {
    setOpenPoint72(!openPoint72);
  };

  const handleClickPoint73 = () => {
    setOpenPoint73(!openPoint73);
  };

  const handleClickPoint74 = () => {
    setOpenPoint74(!openPoint74);
  };

  const handleClickPoint75 = () => {
    setOpenPoint75(!openPoint75);
  };
  
  const getPageByMenuValueApiRequest = async (menuValue) => {
    console.log("[getPageByMenuValueApiRequest] leggo page con menuValue =",menuValue);
    const url = `/api/pages/menuValue/`+menuValue;
    const { data } = await axios.get(url);
    console.log(`data =`, data);  
    return data
  }

  useEffect(() => {
    getPageByMenuValueApiRequest('SHOP').then((data) => { 
      console.log(`data 1 =`, data);  
      setPage(data);
      setTitle(data?.title.text);
      setSubTitle(data?.subTitle.text);
      setDescription(data?.description.text);
      setPrice(data?.price);
      setId(data?._id);
      setPathImage1(data?.images[0].path);
      setPathImage2(data?.images[1].path);
      setPathImage3(data?.images[2].path);
      setPathImage4(data?.images[3].path);
      setPathImage5(data?.images[4].path);

      setPathImage11(data?.images[5].path);
      setPathImage12(data?.images[6].path);
      setPathImage13(data?.images[7].path);
      setPathImage14(data?.images[8].path);
      setPathImage15(data?.images[9].path);
      setPathImage31(data?.images[10].path);
      setPathImage41(data?.images[11].path);
      setPathImage51(data?.images[12].path);

      setPathImage61(data?.images[13].path);
      setPathImage62(data?.images[14].path);
      setPathImage63(data?.images[15].path);
      setPathImage64(data?.images[16].path);

      setPathImage71(data?.images[17].path);
      setPathImage72(data?.images[18].path);
      setPathImage73(data?.images[19].path);
      setPathImage74(data?.images[20].path);

      setPathImage21(data?.images[21].path);

      setCustomizedName(page?.customizedContent.name.text);
      setCustomizedStreet(page?.customizedContent.street.text);
      setCustomizedZip(page?.customizedContent.zip.text);
      setCustomizedCity(page?.customizedContent.city.text);
      setCustomizedEmail(page?.customizedContent.email.text);
      setTitleImage1(data?.imagesTitle[0].text);
      setSubTitleImage1(data?.imagesSubTitle[0].text);
      setTitleImage2(data?.imagesTitle[1].text);
      setSubTitleImage2(data?.imagesSubTitle[1].text);
      setTitleImage3(data?.imagesTitle[2].text);
      setSubTitleImage3(data?.imagesSubTitle[2].text);
      setTitleImage4(data?.imagesTitle[3].text);
      setSubTitleImage4(data?.imagesSubTitle[3].text);
      setTitleImage5(data?.imagesTitle[4].text);
      setSubTitleImage5(data?.imagesSubTitle[4].text);
      console.log(`page?.title.text =`, page?.title.text);  
      console.log(`page?.description.text =`, page?.description.text);  
      console.log(`page?.price =`, page?.price);  
      console.log(`page?.customizedContent =`, page?.customizedContent);  
      console.log(`page?.customizedContent.name.text =`, page?.customizedContent.zip.text);  
    })
    .catch((er) => {
      console.log(er);
    });  
  }, [title,id])

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (

    <Container className="px-0">
    
    <Row
      g-0
      className="pt-2 pt-md-5 w-100 px-4 px-xl-0 position-relative"
    >
    
      <Col
        md={{ size: 4 }}
        tag="aside"
        className="pb-5 mb-5 pb-md-0 mb-md-0 mx-auto mx-md-0"
      >

        <Carousel> 
          <Carousel.Item interval={config.DELAY_SLIDE_PAGE}> 
            <img 
              className="d-block w-100"
              src={pathImage1}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{titleImage1}</h3> 
              <p>{subTitleImage1}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
          <Carousel.Item interval={config.DELAY_SLIDE_PAGE}> 
            <img 
              className="d-block w-100"
              src={pathImage2}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{titleImage2}</h3> 
              <p>{subTitleImage2}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
          <Carousel.Item interval={config.DELAY_SLIDE_PAGE}> 
            <img 
              className="d-block w-100"
              src={pathImage3}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{titleImage3}</h3> 
              <p>{subTitleImage3}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
          <Carousel.Item interval={config.DELAY_SLIDE_PAGE}> 
            <img 
              className="d-block w-100"
              src={pathImage4}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{titleImage4}</h3> 
              <p>{subTitleImage4}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
          <Carousel.Item interval={config.DELAY_SLIDE_PAGE}> 
            <img 
              className="d-block w-100"
              src={pathImage5}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{titleImage5}</h3> 
              <p>{subTitleImage5}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
        </Carousel> 

      </Col>

      <Col
        md={{ size: 5, offset: 0 }}
        tag="section"
        className="py-5 mb-5 py-md-0 mb-md-0"
      >

        <h2
          style={{
            color: "#1136a0",
          }}
          >
          {title}
        </h2>
        
        <Form.Group className="mb-3" controlId="validationCustom01" >
          <Form.Label><em><b>{subTitle}</b></em></Form.Label>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>
            {description} 
          </Form.Label>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Button variant="primary" size="small" onClick={() => openInNewTab('https://myshoponline.it')} >
            accedi a myShop
          </Button>
          <Typography sx={{ color: '#1136a0', fontSize: 12, paddingBottom: 1 }}>
            &nbsp;&nbsp;clicca per accedere al portale esempio
          </Typography>
        </Form.Group>

      </Col>
    
      <Col
        md={{ size: 3, offset: 0 }}
      >

        <Row className="m-0">
         
            <Col md={12}>     


            <h1
              style={{
                color: "#298458",
              }}
              >
              MyShop
            </h1>

              <Box
                sx={{ '& > :not(style)': { m: 1 } }}
                noValidate
                autoComplete="off"
              >
                <TextField id="id-name" label="Prezzo" variant="outlined" value={strEuroCurrency(price)} 
                                style={{
                                  width: "13ch",
                                }}
                
                  size="small"
                  slotProps={{
                    input: { readOnly: true },
                    inputLabel: { shrink: true },
                  }}             
                  onChange={(event) => {
                  //setName(event.target.value);
                  }}
                />

              <Button variant='primary' size="small">
                <a href="/files-dwn/shop-v1.pdf" target="_blank" download                 
                  style={{
                    color: "#FFFFFF",
                    textDecoration: "none"
                  }
                }>
                  PDF<DownloadTwoToneIcon></DownloadTwoToneIcon>
                </a>
              </Button>

             </Box>
             <Typography sx={{ color: '#1136a0', fontSize: 12 }}>
                  &nbsp;&nbsp;&nbsp;URL a tua scelta, assistenza. Tutto in 3 giorni
             </Typography>

             <Col md={8}>     
             </Col>
            <Col md={6}>     
            </Col>

             <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' , color: '#294784' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >


                <ListItemButton onClick={handleClickPoint11}>
                  <ListItemIcon>
                    <AssessmentTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Catalogo prodotti e ricerca" />
                  {openPoint41 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openPoint11} timeout="auto" unmountOnExit>
                 <Typography sx={{ color: '#1136a0', fontSize: 12 , border: 1 , padding: 1}}>
                    <li>Migliaia di prodotti suddivisi in categorie</li>
                    <ul>
                      <li>aggiungi categorie per meglio definire i tuoi prodotti</li>
                      <li>ad ogni categorie puoi aggiungere quanti prodotti vuoi</li>
                      <li>per ciascun prodotto carica 5 foto</li>
                      <li>pagamento con carta di credito mediante Stripe™</li>
                      <li>email di accredito ad ogni vendita</li>
                      <li>emails consuntive sulle vendite per giorno/settimana/mese/periodo</li>
                      <li>puoi cambiare immagini e testi quando vuoi</li>
                      <li>grafici con statistiche immediate</li>
                    </ul>
                    <li>Puoi esportare qualsiasi elenco Clienti, transazioni ... in formato Excel</li>
                  </Typography>

                  <List component="div" disablePadding>
                    <ListItemButton onClick={handleClickPoint12} sx={{ pl: 4 , color: '#298458' }}>
                      <ListItemIcon>
                        <AssessmentTwoToneIcon />
                      </ListItemIcon>
                      <ListItemText primary="carica foto da Mobile" />
                    </ListItemButton>
                    <Collapse in={openPoint12} timeout="auto" unmountOnExit>
                      <Box
                          component="img"
                          sx={{
                            height: 233,
                            width: 350,
                            maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 350, md: 250 },
                          }}
                          alt="The house from the offer."
                          src={pathImage12}
                      />
                      <Typography sx={{ color: '#1136a0', fontSize: 12 }}>
                        &nbsp;storico 
                      </Typography>
                    </Collapse>

                    <ListItemButton onClick={handleClickPoint13} sx={{ pl: 4, color: '#298458' }}>
                      <ListItemIcon>
                        <AssessmentTwoToneIcon />
                      </ListItemIcon>
                      <ListItemText primary="pagamento elettronico immediato" />
                    </ListItemButton>
                    <Collapse in={openPoint13} timeout="auto" unmountOnExit>
                      <Box
                          component="img"
                          sx={{
                            height: 233,
                            width: 350,
                            maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 350, md: 250 },
                          }}
                          alt="The house from the offer."
                          src={pathImage13}
                      />
                      <Typography sx={{ color: '#1136a0', fontSize: 12 }}>
                        &nbsp;categorie 
                      </Typography>
                    </Collapse>

                    <ListItemButton onClick={handleClickPoint14} sx={{ pl: 4 , color: '#298458' }}>
                      <ListItemIcon>
                        <AssessmentTwoToneIcon />
                      </ListItemIcon>
                      <ListItemText primary="ricevi email per ogni vendita" />
                    </ListItemButton>
                    <Collapse in={openPoint14} timeout="auto" unmountOnExit>
                      <Box
                          component="img"
                          sx={{
                            height: 233,
                            width: 350,
                            maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 350, md: 250 },
                          }}
                          alt="The house from the offer."
                          src={pathImage14}
                      />
                      <Typography sx={{ color: '#1136a0', fontSize: 12 }}>
                        &nbsp;servizi 
                      </Typography>
                    </Collapse>

                    <ListItemButton onClick={handleClickPoint15} sx={{ pl: 4 , color: '#298458' }}>
                      <ListItemIcon>
                        <AssessmentTwoToneIcon />
                      </ListItemIcon>
                      <ListItemText primary="ricevi email per consuntivi giorno/settimana/mese" />
                    </ListItemButton>
                    <Collapse in={openPoint15} timeout="auto" unmountOnExit>
                      <Box
                          component="img"
                          sx={{
                            height: 233,
                            width: 350,
                            maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 350, md: 250 },
                          }}
                          alt="The house from the offer."
                          src={pathImage15}
                      />
                      <Typography sx={{ color: '#1136a0', fontSize: 12 }}>
                        &nbsp;risorse/dipendenti 
                      </Typography>
                    </Collapse>

                  </List>
                </Collapse>

                <ListItemButton onClick={handleClickPoint21}>
                  <ListItemIcon>
                    <SourceTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Contenuti dinamici"/>
                  {openPoint21 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openPoint21} timeout="auto" unmountOnExit>
                  <Typography sx={{ color: '#1136a0', fontSize: 12 , border: 1 , paddingTop: 1}}>
                    <ul>
                      <li>imposta campagne promozionali ai clienti registrati</li>
                      <li>con accesso Admin puoi cambiare foto, prezzi e quantità disponibili</li>
                      <li>carica le foto dal tuo telefono, verranno ottimizzate in automatico</li>
                      <li>cambia le immagini e i testi per occasioni/eventi</li>
                    </ul>
                  </Typography>
                  <Box
                      component="img"
                      sx={{
                        height: 233,
                        width: 350,
                        maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 250 },
                      }}
                      alt="The house from the offer."
                      src={pathImage21}
                  />
                </Collapse>

                <ListItemButton onClick={handleClickPoint31}>
                  <ListItemIcon>
                    <InsertInvitationTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="il cliente si registra e acquista" />
                  {openPoint31 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openPoint31} timeout="auto" unmountOnExit>
                  <Box
                      component="img"
                      sx={{
                        height: 233,
                        width: 350,
                        maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 250 },
                      }}
                      alt="The house from the offer."
                      src={pathImage31}
                  />
                  <Typography sx={{ color: '#1136a0', fontSize: 12 }}>
                    &nbsp;&nbsp;&nbsp;clienti abilitati alla prenotazione 
                  </Typography>
                </Collapse>

                <ListItemButton onClick={handleClickPoint41}>
                  <ListItemIcon>
                    <StyleTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Coupons promozionali" />
                  {openPoint41 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openPoint41} timeout="auto" unmountOnExit>
                 <Typography sx={{ color: '#1136a0', fontSize: 12 , border: 1 , padding: 1}}>
                    <li>Crea offerte promozionali con sconti percentuali</li>
                    <ul>
                      <li>imposta il periodo</li>
                      <li>manda email di invito a tutti i clienti</li>
                    </ul>
                    <li>Puoi esportare qualsiasi elenco Clienti, transazioni ... in formato Excel</li>
                  </Typography>
                  <Box
                      component="img"
                      sx={{
                        height: 233,
                        width: 350,
                        maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 250 },
                      }}
                      alt="The house from the offer."
                      src={pathImage41}
                  />

            {/* 
                  <List component="div" disablePadding>
                    <ListItemButton onClick={handleClickPoint42} sx={{ pl: 4 , color: '#298458' }}>
                      <ListItemIcon>
                        <AssessmentTwoToneIcon />
                      </ListItemIcon>
                      <ListItemText primary="imposta i coupons stagionali" />
                    </ListItemButton>
                    <Collapse in={openPoint42} timeout="auto" unmountOnExit>
                      <Box
                          component="img"
                          sx={{
                            height: 233,
                            width: 350,
                            maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 350, md: 250 },
                          }}
                          alt="The house from the offer."
                          src={pathImage41}
                      />
                      <Typography sx={{ color: '#1136a0', fontSize: 12 }}>
                        &nbsp;imposta la scontistica quando vuoi  
                      </Typography>
                    </Collapse>

                  </List>
            */}

                </Collapse>

                <ListItemButton onClick={handleClickPoint51}>
                  <ListItemIcon>
                    <StyleTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Giftcard carte regalo" />
                  {openPoint51 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openPoint51} timeout="auto" unmountOnExit>
                 <Typography sx={{ color: '#1136a0', fontSize: 12 , border: 1 , padding: 1}}>
                    <li>Crea carte prepagate virtuali per acquisti</li>
                    <ul>
                      <li>gestisci gli acquisti prepagati</li>
                      <li>il tuo cliente può regalare buoni acquisto</li>
                      <li>chi riceve la giftcard potrà acquistare scalando il credito</li>
                    </ul>
                    <li>Puoi controllare gli importi di acquisto restanti</li>
                  </Typography>
                  <Box
                      component="img"
                      sx={{
                        height: 233,
                        width: 350,
                        maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 250 },
                      }}
                      alt="The house from the offer."
                      src={pathImage51}
                  />

            {/* 
                  <List component="div" disablePadding>
                    <ListItemButton onClick={handleClickPoint52} sx={{ pl: 4 , color: '#298458' }}>
                      <ListItemIcon>
                        <AssessmentTwoToneIcon />
                      </ListItemIcon>
                      <ListItemText primary="gestisci le carte regalo" />
                    </ListItemButton>
                    <Collapse in={openPoint52} timeout="auto" unmountOnExit>
                      <Box
                          component="img"
                          sx={{
                            height: 233,
                            width: 350,
                            maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 350, md: 250 },
                          }}
                          alt="The house from the offer."
                          src={pathImage51}
                      />
                      <Typography sx={{ color: '#1136a0', fontSize: 12 }}>
                        &nbsp;sono prepagate, saldate al momento dell'acquisto 
                      </Typography>
                    </Collapse>
                    </List>
            */}

                </Collapse>

                <ListItemButton onClick={handleClickPoint60}>
                  <ListItemIcon>
                    <AssessmentTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Consuntivi e statistiche" />
                  {openPoint60 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openPoint60} timeout="auto" unmountOnExit>
                 <Typography sx={{ color: '#1136a0', fontSize: 12 , border: 1 , padding: 1}}>
                    <li>Avrai sempre a disposizione elenchi e grafici che riportano l'andamento tra due date:</li>
                    <ul>
                      <li>grafici sulle categorie vendute</li>
                      <li>grafici sui servizi/prodotti venduti</li>
                      <li>grafici sull'acquisto per clienti e zone</li>
                      <li>grafici storici</li>
                    </ul>
                    <li>Puoi esportare qualsiasi elenco Clienti, Servizi... in formato Excel</li>
                  </Typography>

                  <List component="div" disablePadding>
                    <ListItemButton onClick={handleClickPoint61} sx={{ pl: 4 , color: '#298458' }}>
                      <ListItemIcon>
                        <AssessmentTwoToneIcon />
                      </ListItemIcon>
                      <ListItemText primary="grafico categorie vendute" />
                    </ListItemButton>
                    <Collapse in={openPoint61} timeout="auto" unmountOnExit>
                      <Box
                          component="img"
                          sx={{
                            height: 233,
                            width: 350,
                            maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 350, md: 250 },
                          }}
                          alt="The house from the offer."
                          src={pathImage61}
                      />
                      <Typography sx={{ color: '#1136a0', fontSize: 12 }}>
                        &nbsp;categorie vendute su base giorni, settimane, mesi ...
                      </Typography>
                    </Collapse>

                    <ListItemButton onClick={handleClickPoint62} sx={{ pl: 4, color: '#298458' }}>
                      <ListItemIcon>
                        <AssessmentTwoToneIcon />
                      </ListItemIcon>
                      <ListItemText primary="grafico prodotti venduti" />
                    </ListItemButton>
                    <Collapse in={openPoint62} timeout="auto" unmountOnExit>
                      <Box
                          component="img"
                          sx={{
                            height: 233,
                            width: 350,
                            maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 350, md: 250 },
                          }}
                          alt="The house from the offer."
                          src={pathImage62}
                      />
                      <Typography sx={{ color: '#1136a0', fontSize: 12 }}>
                        &nbsp;prodotti vendute su base giorni, settimane, mesi ... 
                      </Typography>
                    </Collapse>

                    <ListItemButton onClick={handleClickPoint63} sx={{ pl: 4 , color: '#298458' }}>
                      <ListItemIcon>
                        <AssessmentTwoToneIcon />
                      </ListItemIcon>
                      <ListItemText primary="grafico del venduto su clienti e zone" />
                    </ListItemButton>
                    <Collapse in={openPoint63} timeout="auto" unmountOnExit>
                      <Box
                          component="img"
                          sx={{
                            height: 233,
                            width: 350,
                            maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 350, md: 250 },
                          }}
                          alt="The house from the offer."
                          src={pathImage63}
                      />
                      <Typography sx={{ color: '#1136a0', fontSize: 12 }}>
                        &nbsp;andamento vendite per zone e q.tà clienti 
                      </Typography>
                    </Collapse>

                    <ListItemButton onClick={handleClickPoint64} sx={{ pl: 4 , color: '#298458' }}>
                      <ListItemIcon>
                        <AssessmentTwoToneIcon />
                      </ListItemIcon>
                      <ListItemText primary="grafico storico del venduto" />
                    </ListItemButton>
                    <Collapse in={openPoint64} timeout="auto" unmountOnExit>
                      <Box
                          component="img"
                          sx={{
                            height: 233,
                            width: 350,
                            maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 350, md: 250 },
                          }}
                          alt="The house from the offer."
                          src={pathImage64}
                      />
                      <Typography sx={{ color: '#1136a0', fontSize: 12 }}>
                        &nbsp;statistiche storiche di vendita 
                      </Typography>
                    </Collapse>

                  </List>
                </Collapse>

                <ListItemButton onClick={handleClickPoint70}>
                  <ListItemIcon>
                    <AutoFixHighTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Scegli il tuo WebStyle" />
                  {openPoint70 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openPoint70} timeout="auto" unmountOnExit>
                <Typography sx={{ color: '#1136a0', fontSize: 12 , border: 1 , padding: 1}}>
                    <li>con MyShop puoi personalizzare il tuo portale scegliendo lo stile che preferisci</li>
                    <li>WebStyle disponibili:</li>
                    <ol>
                      <li>Classic</li>
                      <li>Jazz</li>
                      <li>Modern</li>
                      <li>Time</li>
                    </ol>
                    <li>Ogni anno rilasciamo altri Stili che potrai adottare</li>
                  </Typography>

                  <List component="div" disablePadding>
                    <ListItemButton onClick={handleClickPoint71} sx={{ pl: 4 , color: '#298458' }}>
                      <ListItemIcon>
                        <ArticleTwoToneIcon />
                      </ListItemIcon>
                      <ListItemText primary="Classic" />
                    </ListItemButton>
                    <Collapse in={openPoint71} timeout="auto" unmountOnExit>
                      <Box
                          component="img"
                          sx={{
                            height: 233,
                            width: 350,
                            maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 350, md: 250 },
                          }}
                          alt="The house from the offer."
                          src={pathImage71}
                      />
                      <Typography sx={{ color: '#1136a0', fontSize: 12 }}>
                        &nbsp;personalizza foto e scritte, cambiale quando vuoi 
                      </Typography>
                    </Collapse>

                    <ListItemButton onClick={handleClickPoint72} sx={{ pl: 4, color: '#298458' }}>
                      <ListItemIcon>
                        <ArticleTwoToneIcon />
                      </ListItemIcon>
                      <ListItemText primary="Jazz" />
                    </ListItemButton>
                    <Collapse in={openPoint72} timeout="auto" unmountOnExit>
                      <Box
                          component="img"
                          sx={{
                            height: 233,
                            width: 350,
                            maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 350, md: 250 },
                          }}
                          alt="The house from the offer."
                          src={pathImage72}
                      />
                      <Typography sx={{ color: '#1136a0', fontSize: 12 }}>
                        &nbsp;personalizza foto e scritte, cambiale quando vuoi 
                      </Typography>
                    </Collapse>

                    <ListItemButton onClick={handleClickPoint73} sx={{ pl: 4 , color: '#298458' }}>
                      <ListItemIcon>
                        <ArticleTwoToneIcon />
                      </ListItemIcon>
                      <ListItemText primary="Modern" />
                    </ListItemButton>
                    <Collapse in={openPoint73} timeout="auto" unmountOnExit>
                      <Box
                          component="img"
                          sx={{
                            height: 233,
                            width: 350,
                            maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 350, md: 250 },
                          }}
                          alt="The house from the offer."
                          src={pathImage73}
                      />
                      <Typography sx={{ color: '#1136a0', fontSize: 12 }}>
                        &nbsp;personalizza foto e scritte, cambiale quando vuoi 
                      </Typography>
                    </Collapse>

                    <ListItemButton onClick={handleClickPoint74} sx={{ pl: 4 , color: '#298458' }}>
                      <ListItemIcon>
                        <ArticleTwoToneIcon />
                      </ListItemIcon>
                      <ListItemText primary="Time" />
                    </ListItemButton>
                    <Collapse in={openPoint74} timeout="auto" unmountOnExit>
                      <Box
                          component="img"
                          sx={{
                            height: 233,
                            width: 350,
                            maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 350, md: 250 },
                          }}
                          alt="The house from the offer."
                          src={pathImage74}
                      />
                      <Typography sx={{ color: '#1136a0', fontSize: 12 }}>
                        &nbsp;personalizza foto e scritte, cambiale quando vuoi  
                      </Typography>
                    </Collapse>

                  </List>
                </Collapse>


              </List>
    
    

            </Col>
          </Row>

    </Col>

           

  </Row>

    <Row className="mt-5 justify-content-md-center">
      <Col md={6}>
      </Col>
    </Row>

  </Container>

  );
};

export default ShopPageComponent;
