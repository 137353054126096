import {
  Navbar,
  Nav,
  Container,
  NavDropdown,
  Badge,
  Form,
  DropdownButton,
  Dropdown,
  Button,
  InputGroup,
} from "react-bootstrap";

import { LinkContainer } from "react-router-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../redux/actions/userActions.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getCategories } from "../redux/actions/categoryActions.js";
import socketIOClient from "socket.io-client";
import { setChatRooms, setSocket, setMessageReceived, removeChatRoom } from "../redux/actions/chatActions.js";

const config = require("../constants/constants.js")

const HeaderComponent2styleC = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userRegisterLogin);
  const itemsCount = useSelector((state) => state.cart.itemsCount);
  const { categories } = useSelector((state) => state.getCategories);
  const { messageReceived } = useSelector((state) => state.adminChat);

  const [searchCategoryToggle, setSearchCategoryToggle] = useState("Tutte le categorie");
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const submitHandler = (e) => {
     if (e.keyCode && e.keyCode !== 13) return;
     e.preventDefault();
     if (searchQuery.trim()) {
         if (searchCategoryToggle === "Tutte le categorie") {
             navigate(`/product-list/search/${searchQuery}`);
         } else {
             navigate(`/product-list/category/${searchCategoryToggle.replaceAll("/", ",")}/search/${searchQuery}`);
         }
     } else if (searchCategoryToggle !== "Tutte le categorie") {
         navigate(`/product-list/category/${searchCategoryToggle.replaceAll("/", ",")}`);
     } else {
         navigate("/product-list");
     }
  }

  useEffect(() => {
      if (userInfo.isAdmin) {
          var audio = new Audio("/audio/chat-msg.mp3");
          const socket = socketIOClient();
          socket.emit("admin connected with server", "Admin" + Math.floor(Math.random() * 1000000000000));
          socket.on("server sends message from client to admin", ({user, message}) => {
              dispatch(setSocket(socket));
        //   let chatRooms = {
        //     fddf54gfgfSocketID: [{ "client": "dsfdf" }, { "client": "dsfdf" }, { "admin": "dsfdf" }],
        //   };
            dispatch(setChatRooms(user, message));      
             dispatch(setMessageReceived(true));  
             audio.play();
          })
          socket.on("disconnected", ({reason, socketId}) => {
            //   console.log(socketId, reason)
            dispatch(removeChatRoom(socketId));
          })
          return () => socket.disconnect();
      }
  },[userInfo.isAdmin])

            {/* 
          <Navbar collapseOnSelect expand="lg" bg="light" data-bs-theme="light">
          */}

  return (


    <Navbar collapseOnSelect variant="light" 
      style={{
        verticalAlign: "top",        
        paddingTop: "0px",
        paddingBottom: "0px",
        background: config.COLOR_BACKGROUND_MENU2_STYLE_C,
      }}
    >

      <Container 
        style={{
          height: "auto",
          background: config.COLOR_BACKGROUND_MENU2_STYLE_C,
        }}
      >
        
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
       
        <Navbar.Collapse id="responsive-navbar-nav">

          <Navbar expand="sm" 
            style={{
              background: config.COLOR_BACKGROUND_MENU2_STYLE_C,
              padding: "0px",
              verticalAlign: "top",
            }}
          >
          
            <Nav>

              <LinkContainer to="/home-menu">
                  <Nav.Link className="mx-2">Home</Nav.Link>
              </LinkContainer>

              <LinkContainer to="/">
                  <Nav.Link className="mx-2">Prodotti</Nav.Link>
              </LinkContainer>

              <LinkContainer to="/competenza">
                <Nav.Link className="mx-2">Aree competenza</Nav.Link>
              </LinkContainer>

              <LinkContainer to="/chi-siamo">
                      <Nav.Link>Chi siamo</Nav.Link>
              </LinkContainer>

              <LinkContainer to="/contatti">
                <Nav.Link className="mx-2">Contatti</Nav.Link>
              </LinkContainer>

        {/* 
              <LinkContainer to="/appuntamenti">
                      <Nav.Link>APPUNTAMENTI</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/">
                      <Nav.Link>SERVIZI</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/chi-siamo">
                      <Nav.Link>CHI SIAMO</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/staff">
                      <Nav.Link>STAFF</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/contatti">
                      <Nav.Link>CONTATTI</Nav.Link>
              </LinkContainer>

              <LinkContainer to="/progetti">
                <Nav.Link className="mx-2">Progetti</Nav.Link>
              </LinkContainer>
              <NavDropdown title="Convegni Eventi" id="collasible-nav-dropdown">
                <LinkContainer to="/convegni-eventi">
                  <NavDropdown.Item> Eventi importanti </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/convegni-argomenti">
                  <NavDropdown.Item> Argomenti </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/convegni-date">
                  <NavDropdown.Item> Prossime date </NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>

              <LinkContainer to="/news">
                <Nav.Link className="mx-2">News</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/contatti">
                <Nav.Link className="mx-2">Contatti</Nav.Link>
              </LinkContainer>
              
              <NavDropdown title="siti PLANNER" id="collasible-nav-dropdown">
                <LinkContainer to="/planner">
                  <NavDropdown.Item> Dettagli </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/planner-prezzi">
                  <NavDropdown.Item> Prezzi </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/planner-stili">
                  <NavDropdown.Item> Stili </NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>

              <NavDropdown title="eCommerce" id="collasible-nav-dropdown">
                <LinkContainer to="/ecommerce">
                  <NavDropdown.Item> Dettagli </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/ecommerce-prezzi">
                  <NavDropdown.Item> Prezzi </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/ecommerce-stili">
                  <NavDropdown.Item> Stili </NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>

              <NavDropdown title="siti STUDIO" id="collasible-nav-dropdown">
                <LinkContainer to="/studio">
                  <NavDropdown.Item> Dettagli </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/studio-prezzi">
                  <NavDropdown.Item> Prezzi </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/studio-stili">
                  <NavDropdown.Item> Stili </NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>

              <NavDropdown title="siti IDEA" id="collasible-nav-dropdown">
                <LinkContainer to="/siti">
                  <NavDropdown.Item> Dettagli </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/siti-prezzi">
                  <NavDropdown.Item> Prezzi </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/siti-stili">
                  <NavDropdown.Item> Stili </NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>

              <NavDropdown title="siti PLACE" id="collasible-nav-dropdown">
                <LinkContainer to="/hotel">
                  <NavDropdown.Item> Dettagli </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/hotel-prezzi">
                  <NavDropdown.Item> Prezzi </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/hotel-stili">
                  <NavDropdown.Item> Stili </NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
        */}



          {/* 
              <NavDropdown title="Eventi" id="collasible-nav-dropdown">
                <LinkContainer to="/eventi">
                  <NavDropdown.Item> Eventi importanti </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/eventi-argomenti">
                  <NavDropdown.Item> Argomenti </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/eventi-date">
                  <NavDropdown.Item> Prossime date </NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>

              <LinkContainer to="/news">
                <Nav.Link className="mx-2">News</Nav.Link>
              </LinkContainer>
          */}
 

            </Nav>
          </Navbar> 

        </Navbar.Collapse>

      </Container>
      
    </Navbar>

);
};

export default HeaderComponent2styleC;
