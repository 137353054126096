import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import 'bootstrap/dist/css/bootstrap.css'; 
import Carousel from 'react-bootstrap/Carousel'; 
import * as React from 'react';
import axios from "axios";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import SwiperCore, { Autoplay, EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "../../App.css";

SwiperCore.use([EffectCoverflow, Autoplay, Pagination]);

const config = require("../../constants/constants.js")

const CONTATTI_SLIDES_URL_ARRAY = [
  "/images-0001/pages/planner/planner-slide3.jpg",
  "/images-0001/pages/studio/studio-slide3.jpg",
  "/images-0001/pages/planner/planner-slide2.jpg",
  "/images-0001/pages/planner/planner-slide1.jpg",
  "/images-0001/pages/shop/shop-slide2.jpg",
  "/images-0001/pages/planner/planner-slide4.jpg",
  "/images-0001/pages/studio/studio-slide4.jpg",
  "/images-0001/pages/shop/shop-slide1.jpg",
  "/images-0001/pages/studio/studio-slide5.jpg",
  "/images-0001/pages/shop/shop-slide3.jpg",
  "/images-0001/pages/shop/shop-slide4.jpg",
  "/images-0001/pages/studio/studio-slide1.jpg",
];

const ContattiPageComponent = ({ loginUserApiRequest,reduxDispatch, setReduxUserState  }) => {

  const [validated, setValidated] = useState(false);
  const [loginUserResponseState, setLoginUserResponseState] = useState({
    success: "",
    error: "",
    loading: false,
  });

  const navigate = useNavigate();
 

  const [name, setName] = useState("");                      
  const [email, setEmail] = useState("");                      
  const [message, setMessage] = useState("");                      
  

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget.elements;

    const email = form.email.value;
    const password = form.password.value;
    const doNotLogout = form.doNotLogout.checked;

    if (event.currentTarget.checkValidity() === true && email && password) {
        setLoginUserResponseState({ loading: true });
      loginUserApiRequest(email, password, doNotLogout)
        .then((res) => {
            setLoginUserResponseState({ success: res.success, loading: false, error: "" });

            if (res.userLoggedIn) {
                reduxDispatch(setReduxUserState(res.userLoggedIn));
            }

            if (res.success === "user logged in" && !res.userLoggedIn.isAdmin) window.location.assign('/user') 
            else window.location.assign('/admin/categories')

        })
        .catch((er) =>
          setLoginUserResponseState({ error: er.response.data.message ? er.response.data.message : er.response.data })
        );
    }

    setValidated(true);
  };
  
  const [page, setPage] = useState({});  
  const [id, setId] = useState("");                      
  const [title, setTitle] = useState("");                      
  const [subTitle, setSubTitle] = useState("");     
  const [description, setDescription] = useState("");     
  const [customizedName, setCustomizedName] = useState("");     
  const [customizedStreet, setCustomizedStreet] = useState("");     
  const [customizedZip, setCustomizedZip] = useState("");     
  const [customizedCity, setCustomizedCity] = useState("");     
  const [customizedEmail, setCustomizedEmail] = useState("");     
  const [tabAttivo, setTabAttivo] = React.useState('home');
  const [pathImage1, setPathImage1] = useState("");     
  const [pathImage2, setPathImage2] = useState("");     
  const [pathImage3, setPathImage3] = useState("");     
  const [titleImage1, setTitleImage1] = useState("");     
  const [subTitleImage1, setSubTitleImage1] = useState("");     
  const [titleImage2, setTitleImage2] = useState("");     
  const [subTitleImage2, setSubTitleImage2] = useState("");     
  const [titleImage3, setTitleImage3] = useState("");     
  const [subTitleImage3, setSubTitleImage3] = useState("");     

  const getPageByMenuValueApiRequest = async (menuValue) => {
    console.log("[getPageByMenuValueApiRequest] leggo page con menuValue =",menuValue);
    const url = `/api/pages/menuValue/`+menuValue;
    const { data } = await axios.get(url);
    console.log(`data =`, data);  
    return data
  }

  useEffect(() => {
    getPageByMenuValueApiRequest('CONTATTI').then((data) => { 
      console.log(`data 1 =`, data);  
      setPage(data);
      setTitle(data?.title.text);
      setSubTitle(data?.subTitle.text);
      setDescription(data?.description.text);
      setTabAttivo('home');
      setId(data?._id);
      setPathImage1(data?.images[0].path);
      setPathImage2(data?.images[1].path);
      setPathImage3(data?.images[2].path);
      setCustomizedName(page?.customizedContent.name.text);
      setCustomizedStreet(page?.customizedContent.street.text);
      setCustomizedZip(page?.customizedContent.zip.text);
      setCustomizedCity(page?.customizedContent.city.text);
      setCustomizedEmail(page?.customizedContent.email.text);
      setTitleImage1(data?.imagesTitle[0].text);
      setSubTitleImage1(data?.imagesSubTitle[0].text);
      setTitleImage2(data?.imagesTitle[1].text);
      setSubTitleImage2(data?.imagesSubTitle[1].text);
      setTitleImage3(data?.imagesTitle[2].text);
      setSubTitleImage3(data?.imagesSubTitle[2].text);
      console.log(`id =`, id);  
      console.log(`page?.title.text =`, page?.title.text);  
      console.log(`page?.description.text =`, page?.description.text);  
      console.log(`page?.customizedContent =`, page?.customizedContent);  
      console.log(`page?.customizedContent.name.text =`, page?.customizedContent.zip.text);  
    })
    .catch((er) => {
      console.log(er);
    });  
  }, [tabAttivo,id])

  return (
   <>

    <div className="main-swiper" >
      
      <Swiper   
        style={{
          paddingTop: "0px",
        }}
        effect={"coverflow"}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        grabCursor={true}
        centeredSlides={false}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 0,
          modifier: 1,
          slideShadows: false,
        }}
        pagination={true}
        className="mySwiper"
      >

      {CONTATTI_SLIDES_URL_ARRAY.map((img, i) => {
        return (
          <SwiperSlide key={i}
          style={{
            width: "150px",
            height: "150px",
          }}
              >
            <img src={img} alt="" />
          </SwiperSlide>
        );
      })}

    </Swiper>

  </div>

    <Container className="px-0">
     <Row className="mt-3 justify-content-md-center">
 
       <Col md={5}>
 
        <Carousel> 
          <Carousel.Item interval={2500}> 
            <img 
              style={{ width:500, height: 300, padding: 0 }}
              src={pathImage1}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{titleImage1}</h3> 
              <p>{subTitleImage1}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
          <Carousel.Item interval={2500}> 
            <img 
              style={{ width:500, height: 300, padding: 0 }}
              src={pathImage2}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{titleImage2}</h3> 
              <p>{subTitleImage2}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
          <Carousel.Item interval={2500}> 
            <img 
              style={{ width:500, height: 300, padding: 0 }}
              src={pathImage3}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{titleImage3}</h3> 
              <p>{subTitleImage3}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
        </Carousel> 
      </Col>

      <Col md={7}>
 
        <h1>{title}</h1>
        <em>&nbsp;{subTitle}</em>

      {/* 
        <Form.Group className="mb-3" controlId="validationCustom01" >
            <Form.Label><em>&nbsp;{subTitle}</em></Form.Label>
        </Form.Group>
      */}


          <Box
              component="form"
              sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
              noValidate
              autoComplete="off"
            >

              <TextField id="id-name" label="il tuo nome" variant="outlined" defaultValue={name}
                style={{
                  width: "400px",
                }}
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />

              <TextField id="id-email" label="la tua email" variant="outlined" defaultValue={email}
                style={{
                  width: "400px",
                }}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />

              <TextField id="id-message" label="il messaggio" variant="outlined" defaultValue={message}
                style={{
                  width: "400px",
                }}
                onChange={(event) => {
                  setMessage(event.target.value);
                }}
              />

        </Box>

      {/* 
        <Form.Group className="mb-3" controlId="validationCustom01">
          <Form.Label>il tuo nome</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="inserisci il nome"
            name="name"
          />
          <Form.Control.Feedback type="invalid">
            Prego inserisci il nome
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>la tua email</Form.Label>
          <Form.Control
            name="email"
            required
            type="email"
            placeholder="inserisci email"
          />
          <Form.Control.Feedback type="invalid">
            inserisci un indirizzo email valido
          </Form.Control.Feedback>
        </Form.Group>
    
        <Form.Group className="mb-3" controlId="validationCustom01">
          <Form.Label>il tuo messaggio</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="inserisci il messaggio"
            name="name"
          />
          <Form.Control.Feedback type="invalid">
            Prego inserisci il messaggio
          </Form.Control.Feedback>
        </Form.Group>
      */}

        <Form noValidate validated={validated} onSubmit={handleSubmit}>

          <Button variant="primary" type="submit">
            {loginUserResponseState &&
            loginUserResponseState.loading === true ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              ""
            )}
            Invia
          </Button>
          <Alert
            show={
              loginUserResponseState &&
              loginUserResponseState.error === "wrong credentials"
            }
            variant="danger"
          >
            Dati sbagliati
          </Alert>
        </Form>

      </Col>

    </Row>
    
    <Row className="mt-5 justify-content-md-center">
      <Col md={6}>
      </Col>
    </Row>

  </Container>
  
  </>

  );
};

export default ContattiPageComponent;
